import {http} from './config';

export default {
    create:(reserve, allotment_id) => {
        return http.post('api/v1/reservas', reserve, {headers: {"allotment-id": allotment_id}});
    },
    update:(data) => {
        return  http.put(`api/v1/reservas/${data.id}`,data);
    },
    index:(filter, allotment_id = null, page=1, perpage = null, exportIn = '') => {
        if (perpage === 'selecione' || !perpage) perpage = null;
        let responseType = exportIn === 'xls' ? 'blob' : '';
        if (allotment_id && allotment_id !== 'all'){
            return http.get('api/v1/reservas?page=' + page, {
                params: {...filter, perpage,exportIn},
                headers: {"allotment-id": allotment_id},
                responseType,
            });
        }
        return http.get('api/v1/reservas?page=' + page, {
            params: {...filter, perpage,exportIn},
            responseType,
        });
    },
    historic:(reserve_id, allotment_id) => {
        return http.get('api/v1/reservas/historico/' + reserve_id, {headers: {"allotment-id": allotment_id}});
    },
    renew:(reserve_id, allotment_id) => {
        return http.get('api/v1/reservas/renovar/' + reserve_id, {headers: {"allotment-id": allotment_id}});
    },
    drop:(reserve_id, allotment_id) => {
        return http.get('api/v1/reservas/revogar/' + reserve_id, {headers: {"allotment-id": allotment_id}});
    },
    undetermined:(reserve_id, allotment_id) => {
        return http.get('api/v1/reservas/indeterminada/' + reserve_id, {headers: {"allotment-id": allotment_id}});
    },
    confirm:(reserve_id, reserve, allotment_id) => {
        return http.post('api/v1/reservas/confirmar/' + reserve_id, reserve, {headers: {"allotment-id": allotment_id}})
    },
    getConfirmedReservesByBlock:(block, allotment_id) => {
        return http.get('api/v1/reservas/confirmadas/' + block, {headers: {"allotment-id": allotment_id}});
    },
    show:(id) => {
        return http.get('api/v1/reservas/' + id);
    },
    showProposals:(id) => {
        return http.get('api/v1/reservas/' + id + '?onlyProposal=true');
    },
    reservesByRealtor:(allotment_id, filter, page, orderBy = null, perPage = null) => {

        return http.get(`api/v1/reservas/${allotment_id}/corretores?page=${page}`, {
            params: {...filter, orderBy, perPage}
        });
    },
    verifyLimitReserve: (allotment_id) => {
        return http.get('/api/v1/reservas/permissao-reservas', {headers: {'allotment-id': allotment_id}});
    },
    attachClient: (data, id) => {
        return http.post(`/api/v1/reservas/vincular-client/${id}`, data)
    },
}
